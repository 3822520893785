.App {
  text-align: center;
  background-color: #2f2f2f;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h2 {
  color: #fff;
}

.App footer, .App header, .App main {
  padding: 1.5rem;
}

.App footer {
  padding-bottom: 6rem;
}

.progress {
  margin-bottom: 2rem;
}


.slider-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.slider-container .slider {
  flex: 1;
  margin-left: 1rem;
  margin-right: 1rem;
}

.slider-container span {
  font-weight: 600;
  color: #fff;
}

.slider-container .ant-btn-dashed {
  background: none;
}

.progress .ant-progress-text {
  font-size: 110%;
  /* color: #fff; */
  font-weight: bold;
}

.controls {
  max-width: 600px;
  margin: 0px auto;
}
